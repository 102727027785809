
import { Component, Vue } from 'vue-property-decorator';
import SubModule from '../../components/SubModule.vue';
import { RouteConfig } from 'vue-router';
import routes from './routes';
import { IMenuItem } from '@/components/Menu.vue';
import store from './store';
import { EntityStoreGetters } from '@/base';

@Component
export default class Task extends SubModule {
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM] {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[] {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[] {
    const activeItem = this.activeItem;
    if (activeItem) {
      if (activeItem.$isCreated()) {
        return [
          {
            title:  `${activeItem.taskName}`,
            icon: `$vuetify.icons.edit`,
            route: `/task/${activeItem.id}`,
          },
        ];
      } else {
        return [
          {
            title: this.$t('form.titleNew').toString(),
            icon: `$vuetify.icons.add`,
            route: `/task/new`,
          },
        ];
      }
    }
    return [];
  }
}
